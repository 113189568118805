export const GET_TOKEN_START = 'GET_TOKEN_START';
export const GET_TOKEN_SUCCESS = 'GET_TOKEN_SUCCESS';
export const GET_TOKEN_FAILURE = 'GET_TOKEN_FAILURE';
export const ADD_PROJECT_START = 'ADD_PROJECT_START';
export const ADD_PROJECT_SUCCESS = 'ADD_PROJECT_SUCCESS';
export const ADD_PROJECT_FAILURE = 'ADD_PROJECT_FAILURE';
export const DELETE_PROJECT_START = 'DELETE_PROJECT_START';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_FAILURE = 'DELETE_PROJECT_FAILURE';
export const GET_USER_START = 'GET_USER_START';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';
export const PAYMENT_CONNECTION_START = 'PAYMENT_CONNECTION_START';
export const PAYMENT_CONNECTION_SUCCESS = 'PAYMENT_CONNECTION_SUCCESS';
export const PAYMENT_CONNECTION_FAILURE = 'PAYMENT_CONNECTION_FAILURE';
export const ONBOARDING_START = 'ONBOARDING_START';
export const ONBOARDING_SUCCESS = 'ONBOARDING_SUCCESS';
export const ONBOARDING_FAILURE = 'ONBOARDING_FAILURE';
export const GET_PROJECTS_START = 'GET_PROJECTS_START';
export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS';
export const GET_PROJECTS_FAILURE = 'GET_PROJECTS_FAILURE';
export const GET_PROJECT_BY_ID_START = 'GET_PROJECT_BY_ID_START';
export const GET_PROJECT_BY_ID_SUCCESS = 'GET_PROJECT_BY_ID_SUCCESS';
export const GET_PROJECT_BY_ID_FAILURE = 'GET_PROJECT_BY_ID_FAILURE';
export const GET_BIDS_START = 'GET_BIDS_START';
export const GET_BIDS_SUCCESS = 'GET_BIDS_SUCCESS';
export const GET_BIDS_FAILURE = 'GET_BIDS_FAILURE';
export const ADD_BID_START = 'ADD_BID_START';
export const ADD_BID_SUCCESS = 'ADD_BID_SUCCESS';
export const ADD_BID_FAILURE = 'ADD_BID_FAILURE';
export const ADD_FEEDBACK_START = 'ADD_FEEDBACK_START';
export const ADD_FEEDBACK_SUCCESS = 'ADD_FEEDBACK_SUCCESS';
export const ADD_FEEDBACK_FAILURE = 'ADD_FEEDBACK_FAILURE';
export const GET_USER_PROJECTS_START = 'GET_USER_PROJECTS_START';
export const GET_USER_PROJECTS_SUCCESS = 'GET_USER_PROJECTS_SUCCESS';
export const GET_USER_PROJECTS_FAILURE = 'GET_USER_PROJECTS_FAILURE';
export const GET_FEEDBACK_BY_CONTRACTOR_ID_START =
  'GET_FEEDBACK_BY_CONTRACTOR_ID_START';
export const GET_FEEDBACK_BY_CONTRACTOR_ID_SUCCESS =
  'GET_FEEDBACK_BY_CONTRACTOR_ID_SUCCESS';
export const GET_FEEDBACK_BY_CONTRACTOR_ID_FAILURE =
  'GET_FEEDBACK_BY_CONTRACTOR_ID_FAILURE';
export const EDIT_PROJECT_START = 'EDIT_PROJECT_START';
export const EDIT_PROJECT_SUCCESS = 'EDIT_PROJECT_SUCCESS';
export const EDIT_PROJECT_FAILURE = 'EDIT_PROJECT_FAILURE';
export const ADD_PROFILE_PIC_START = 'ADD_PROFILE_PIC_START';
export const ADD_PROFILE_PIC_SUCCESS = 'ADD_PROFILE_PIC_SUCCESS';
export const ADD_PROFILE_PIC_FAILURE = 'ADD_PROFILE_PIC_FAILURE';
export const ADD_PROJECT_PICS_START = 'ADD_PROJECT_PICS_START';
export const ADD_PROJECT_PICS_SUCCESS = 'ADD_PROJECT_PICS_SUCCESS';
export const ADD_PROJECT_PICS_FAILURE = 'ADD_PROJECT_PICS_FAILURE';
export const GET_PROJECT_IMAGES_START = 'GET_PROJECT_IMAGES_START';
export const GET_PROJECT_IMAGES_SUCCESS = 'GET_PROJECT_IMAGES_SUCCESS';
export const GET_PROJECT_IMAGES_FAILURE = 'GET_PROJECT_IMAGES_FAILURE';
export const PAYMENT_TRANSFER_START = 'PAYMENT_TRANSFER_START';
export const PAYMENT_TRANSFER_SUCCESS = 'PAYMENT_TRANSFER_SUCCESS';
export const PAYMENT_TRANSFER_FAILURE = 'PAYMENT_TRANSFER_FAILURE';
export const GET_SERVICES_START = 'GET_SERVICES_START';
export const GET_SERVICES_SUCCESS = 'GET_SERVICES_SUCCESS';
export const GET_SERVICES_FAILURE = 'GET_SERVICES_FAILURE';
export const DELETE_BID_START = 'DELETE_BID_START';
export const DELETE_BID_SUCCESS = 'DELETE_BID_SUCCESS';
export const DELETE_BID_FAILURE = 'DELETE_BID_FAILURE';